import { Box, Grid, Typography, useMediaQuery, Button } from '@mui/material'
import Logo from '../assets/logo/offsiteio-logo.svg'
import { useState } from 'react'
import Airplanes2 from '../assets/img/airplanes2.svg'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const navigate = useNavigate()
    const [fullName, setFullName] = useState(null)
    const [email, setEmail] = useState(null)
    const styles = {
        logo: {
            margin: '5%',
            marginBottom: '2%',
        }
    };

    const onSubmit = async () => {
        navigate('/')
    }

    return (
        <Grid container style={{
            height: mdDown ? '100%' : '100vh',
            minHeight: '100vh',
            overflow: 'hidden',
        }}>
            <Box item style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'centers', alignItems: 'center' }}>
                <img src={Logo} alt='Offsiteio Logo' style={styles.logo} />

                <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h2" component="h2" gutterBottom>
                        404 Not Found
                    </Typography>

                    <Button
                        variant="contained"
                        sx={{
                            marginTop: '7%',
                            width: '25%',
                            height: '3.4rem',
                        }}
                        color="secondary"
                        onClick={onSubmit}
                    >
                        Home
                    </Button>
                </Box>
                <img src={Airplanes2} alt='Airplanes Illustration' style={{ width: '100%', position: 'relative', top: 55 }} />
            </Box>
        </Grid>
    )
}

export default NotFound