import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import Logo from '../assets/logo/offsiteio-logo.svg'
import { useContext, useState, useEffect } from 'react'
import Airplanes2 from '../assets/img/airplanes2.svg'
import HotelIllustration3 from '../assets/img/hotel3.svg'
import HotelIllustration4 from '../assets/img/hotel4.svg'
import HotelIllustration5 from '../assets/img/hotel5.svg'
import Button from '../components/Button'
import Pill from '../components/Pill'
import WhiteStar from '../assets/icons/white-star.svg'
import BlackStar from '../assets/icons/black-star.svg'
import { useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Context } from '../Context'
import Steps from '../components/Steps'

const hotelClasses = [
    3, 4, 5
]

const HotelClass = () => {
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const [name, setName, email, setEmail, city, setCity, month, setMonth, nights, setNights, hotelClass, setHotelClass, colleagues, setColleagues, additionalServices, setAdditionalServices] = useContext(Context)
    const [selected, setSelected] = useState(hotelClasses[1])
    const [pillHovered, setPillHovered] = useState(null)
    const navigate = useNavigate()
    const styles = {
        logo: {
            margin: '3%',
            marginBottom: '1%',
            alignSelf: 'flex-end',
            width: '10%'
        },
        titleMobile: {
            position: 'absolute',
            top: '3%',
            width: '90%',
        }
    };

    const handleSelect = (value) => {
        setSelected(value)
        setHotelClass(value)
    }

    const onSubmit = async () => {
        if (hotelClass) {
            navigate('/select-colleagues')
        }
    }

    const handlePillHover = (hc) => {
        setPillHovered(hc)
    }

    const handleIllustrations = (hotel) => {
        switch (hotel) {
            case 3:
                return HotelIllustration3
            case 4:
                return HotelIllustration4
            case 5:
                return HotelIllustration5
            default:
                return HotelIllustration3
        }
    }

    useEffect(() => {
        setHotelClass(selected)
    }, [])

    
    useEffect(() => {
        if (!name || !email || !city || !month || !nights) {
            navigate('/')
        }
    }, [])

    return (
        <Grid container style={{
            height: mdDown ? '100%' : '100vh',
            minHeight: '100vh',
            overflow: 'hidden',
        }}>
            <Grid item md={7} xs={12} style={{ backgroundColor: '#EFC2CB', height: mdDown ? '40vh' : '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', overflow: 'hidden' }}>
                <CSSTransition
                    key={selected}
                    classNames='fade'
                    in={true}
                    appear={true}
                    timeout={300}
                >
                    <img src={handleIllustrations(selected)} alt='Night Illustration' style={{ width: '90%', paddingBottom: '1vh' }} />
                </CSSTransition>
                {mdDown &&
                    <Typography variant="h2" component="h2" gutterBottom style={styles.titleMobile}>
                        What quality of accommodations do you have mind?
                    </Typography>
                }
            </Grid>
            <Grid item md={5} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', minHeight: mdDown ? '60vh' : '100vh' }}>
                {!mdDown && <img src={Logo} alt='Offsiteio Logo' style={styles.logo} />}
                <Steps mdDown={mdDown} step={4} />
                <Box style={{ width: mdDown ? '90%' : '70%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '5%', height: mdDown ? 'auto' : '60vh', minHeight: mdDown ? 0 : '60vh', zIndex: 5 }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {!mdDown && <Typography variant="h3" component="h3" gutterBottom>
                            What quality of accommodations do you have mind?
                        </Typography>}
                    </Box>
                    <Box sx={{ m: 5, mb: mdDown ? 5 : 0, mt: mdDown ? 0 : 3, width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        {hotelClasses.map(hc =>
                            <div
                                style={{ width: '30%', height: '40px' }}
                                onMouseEnter={() => handlePillHover(hc)}
                                onMouseLeave={() => handlePillHover(null)}
                            >
                                <Pill
                                    text={hc}
                                    fontSize={'1.5rem'}
                                    onSelect={handleSelect}
                                    selected={hc === selected}
                                    icon={hc === selected || pillHovered === hc ? WhiteStar : BlackStar}
                                    w100
                                />
                            </div>
                        )}
                    </Box>
                    <Button
                        text='Continue'
                        styles={{
                            marginTop: '7%',
                            width: '100%',
                            height: '3.5rem',
                            minHeight: '3.5rem'
                        }}
                        onSubmit={onSubmit}
                    />
                </Box>
                <img src={Airplanes2} alt='Airplanes Illustration' style={{ width: '100%', position: 'relative' }} />
            </Grid>
        </Grid>
    )
}

export default HotelClass