import { Box, Grid, Typography, useMediaQuery, TextField, InputAdornment } from '@mui/material'
import Logo from '../assets/logo/offsiteio-logo.svg'
import { useState, useContext, useEffect } from 'react'
import Airplanes2 from '../assets/img/airplanes2.svg'
import Steps from '../components/Steps'
import Button from '../components/Button'
import Pill from '../components/Pill'
import { useNavigate } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import {Context} from '../Context'
import {showToast} from '../utils/showToast'
import UsaMap from '../components/UsaMap'

const cities = [
    'Miami',
    'Austin',
    'Las Vegas',
    'Chicago',
    'Nashville',
    'New York',
    'Los Angeles',
    'San Diego',
    'Not listed...'
]

const Cities = () => {
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [name, setName, email, setEmail, city, setCity, month, setMonth, nights, setNights, hotelClass, setHotelClass, colleagues, setColleagues, additionalServices, setAdditionalServices] = useContext(Context)
    const navigate = useNavigate()
    const [selected, setSelected] = useState([])
    const [showInput, setShowInput] = useState(false)
    const [animationActive, setAnimationActive] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const [notListed, setNotListed] = useState(false)
    const [otherCity, setOtherCity] = useState('')
    const styles = {
        logo: {
            margin: '3%',
            marginBottom: '1%',
            alignSelf: 'flex-end',
            width: '10%'
        },
        titleMobile: {
            position: 'absolute',
            top: '3%',
            width: '90%',
        }
    }
    
    const onSubmit = async () => {
       if (notListed && otherCity) {
            const currentCity = city
            currentCity.push(otherCity)
            setCity(currentCity)
            navigate('/select-month')
       } else if (notListed && !otherCity) {
            showToast('error', 'Please type the city you are interested in')
       } else {
           if (city && city.length > 0) {
                navigate('/select-month')
            } else {
                showToast('error', 'Please select a city or type the city')
            }
       }
    }

    const handleSelect = (opt) => {
        const currentSelected = selected
        let newSelected = []
        if (opt === 'Not listed...') {
            if (!notListed) {
                setNotListed(true)
                setAnimationActive(true)
                setShowInput(true)
            } else {
                setNotListed(false)
                setAnimationActive(false)
                setShowInput(false)
            }
        } else if (currentSelected.includes(opt)) {
            newSelected = currentSelected.filter(el => el !== opt)
            setSelected(newSelected)
            setCity(newSelected)
        } else {
            currentSelected.push(opt)
            setSelected(currentSelected)
            setCity(currentSelected)
        }
        setTrigger(!trigger)
        /*setSelected(opt)
        if (opt === 'Not listed...') {
            setAnimationActive(true)
            setShowInput(true)
            setCity(otherCity)
        }
        if (opt !== 'Not listed...') {
            setShowInput(false)
            setCity(opt)
        }*/
    }

    const handleSelected = (item) => {
        const currentSelected = selected
        const isSelected = currentSelected.includes(item)
        return isSelected
    }

    const handleOtherCity = (value) => {
        setOtherCity(value)
    }

    useEffect(() => {
        if (!name || !email) {
            navigate('/')
        }
    }, [])

    useEffect(() => {

    }, [selected.length, trigger, notListed])

    return (
        <Grid container style={{
            height: mdDown ? '100%' : '100vh',
            minHeight: '100vh',
            overflow: 'hidden'
        }}>
            <Grid item md={7} xs={12} style={{ backgroundColor: '#EFC2CB', height: mdDown ? '40vh' : '100vh', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                <UsaMap selected={selected} mdDown={mdDown} />
                {mdDown && 
                <Typography variant="h2" component="h2" gutterBottom style={styles.titleMobile}>
                    What cities are you interested in?
                </Typography>
                }
            </Grid>
            <Grid item md={5} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', minHeight: mdDown ? '60vh' : '100vh' }}>
                {!mdDown && <img src={Logo} alt='Offsiteio Logo' style={styles.logo} />}
                <Steps mdDown={mdDown} step={1} />
                <Box style={{ width: mdDown ? '90%' : '70%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '5%', height: mdDown ? '30vh' : '60vh', minHeight: mdDown ? 0 : '60vh', zIndex: 5 }}>
                    
                    {!mdDown &&
                        <Typography variant="h3" component="h3" gutterBottom>
                            What cities are you interested in?
                        </Typography>}
                    <Box sx={{ m: 5, mb: 0, mt: mdDown ? 0 : 5, width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        {cities.map(c => <div style={{ width: '30%', marginBottom: '2%' }}><Pill fontSize={'0.8rem'} text={c} onSelect={handleSelect} defaultValue={selected.includes(c)} selected={handleSelected(c) || (c === 'Not listed...' && notListed)} w100 /></div>)}
                    </Box>
                    {
                        animationActive ?
                            <CSSTransition
                                classNames='fade'
                                in={showInput}
                                appear={true}
                                timeout={300}
                            >
                                <TextField
                                    size="medium"
                                    sx={{ marginTop: '2%', width: '100%' }}
                                    onChange={e => handleOtherCity(e.target.value)}
                                    value={otherCity}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" />
                                        )
                                    }}
                                    placeholder={'Type the city'}
                                    type='text'
                                />
                            </CSSTransition>
                            :
                            <div style={{ height: '1.4375em', padding: '16.5px 14px', marginTop: '1.5%' }} />
                    }
                    <Button
                        text='Continue'
                        styles={{
                            marginTop: '7%',
                            width: '100%',
                            height: '3.5rem',
                            minHeight: '3.5rem'
                        }}
                        onSubmit={onSubmit}
                    />
                </Box>
                <img src={Airplanes2} alt='Airplanes Illustration' style={{ width: '100%', position: 'relative', bottom: 0 }} />
            </Grid>
        </Grid>
    )
}

export default Cities