import { Box, Grid, Typography, useMediaQuery, FormGroup, FormControlLabel, Checkbox, TextField, InputAdornment } from '@mui/material'
import Logo from '../assets/logo/offsiteio-logo.svg'
import { useState, useEffect, useContext } from 'react'
import Airplanes2 from '../assets/img/airplanes2.svg'
import ServicesIllustration from '../assets/img/services.svg'
import ServicesIllustration2 from '../assets/img/services-2.svg'
import ServicesIllustration3 from '../assets/img/services-3.svg'
import ServicesIllustration4 from '../assets/img/services-4.svg'
import ServicesIllustration5 from '../assets/img/services-5.svg'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Context } from '../Context'
import { showToast } from '../utils/showToast'
import Steps from '../components/Steps'

const options = [
    "Team Building Exercises",
    "Motivational / Guest Speaker",
    "Wellness Activities",
    "Charity Activities",
    "City Tours / Cultural Outings",
    "Nightclub Experience",
    "Cocktail Party"
]

const images = [
    ServicesIllustration,
    ServicesIllustration2,
    ServicesIllustration3,
    ServicesIllustration4,
    ServicesIllustration5
]

const AdditionalServices = () => {
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const [name, setName, email, setEmail, city, setCity, month, setMonth, nights, setNights, hotelClass, setHotelClass, colleagues, setColleagues, additionalServices, setAdditionalServices] = useContext(Context)
    const navigate = useNavigate()
    const [otherIdea, setOtherIdea] = useState('')
    const [selected, setSelected] = useState([])
    const [trigger, setTrigger] = useState(false)
    const [selectedImage, setSelectedImage] = useState(images[0])
    const [loading, setLoading] = useState(false)
    const [iframeRef, setIframeRef] = useState(null)

    const styles = {
        logo: {
            margin: '3%',
            marginBottom: '1%',
            alignSelf: 'flex-end',
            width: '10%'
        },
        steps: {
            width: mdDown ? '90%' : '60%',
            marginTop: mdDown ? '5%' : '0%',
            marginBottom: mdDown ? '0%' : '5%'
        },
        titleMobile: {
            position: 'absolute',
            top: '3%',
            width: '90%',
        }
    };

    const handleSelect = (opt) => {
        const currentSelected = selected
        let newSelected = []
        if (currentSelected.includes(opt)) {
            newSelected = currentSelected.filter(el => el !== opt)
            setSelected(newSelected)
            setAdditionalServices(newSelected)
            if (currentSelected.length <= 5) {
                setSelectedImage(images[currentSelected.length - 1])
            } else if (currentSelected.length > 5) {
                setSelectedImage(images[4])
            } else {
                setSelectedImage(images[0])
            }
        } else {
            currentSelected.push(opt)
            setSelected(currentSelected)
            setAdditionalServices(currentSelected)
            if (currentSelected.length <= 5) {
                setSelectedImage(images[currentSelected.length - 1])
            } else if (currentSelected.length > 5) {
                setSelectedImage(images[4])
            } else {
                setSelectedImage(images[0])
            }
        }
        setTrigger(!trigger)
    }

    const handleOtherIdea = (value) => {
        setOtherIdea(value)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        
        // Create a copy of the current services
        let currentServices = [...additionalServices]
        
        // Add other idea to services if it exists
        if (otherIdea.trim()) {
            currentServices.push(otherIdea.trim())
        }
        
        if (currentServices.length > 0) {
            setLoading(true)
            if (iframeRef) {
                // Update the services hidden input value right before submission
                const servicesInput = iframeRef.querySelector('input[name="services"]')
                if (servicesInput) {
                    servicesInput.value = currentServices.join(', ')
                }
                
                iframeRef.submit()
                setTimeout(() => {
                    setLoading(false)
                    navigate('/thanks')
                }, 1000)
            }
        } else {
            showToast('error', 'Please select an option or type a service you would like to ask for')
        }
    }

    useEffect(() => { }, [selected.length, trigger])

    useEffect(() => {
        if (!name || !email || !city || !month || !nights || !hotelClass || !colleagues) {
            navigate('/')
        }
    }, [])

    return (
        <>
            <iframe name="hidden_iframe" id="hidden_iframe" style={{display: 'none'}} />
            
            <Grid container style={{
                height: mdDown ? '100%' : '100vh',
                minHeight: '100vh',
                overflow: 'hidden',
            }}>
                <Grid item md={7} xs={12} style={{ backgroundColor: '#EFC2CB', height: mdDown ? '40vh' : '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
                    <CSSTransition
                        key={selected}
                        classNames='fade'
                        in={true}
                        appear={true}
                        timeout={200}
                    >
                        <img src={selectedImage} alt='Services Illustration' style={{ width: selectedImage === ServicesIllustration ? '40%' : '80%' }} />
                    </CSSTransition>
                    {mdDown &&
                        <Typography variant="h2" component="h2" gutterBottom style={styles.titleMobile}>
                            Can we help with any additional services to make your offsite more awesome?
                        </Typography>
                    }
                </Grid>
                <Grid item md={5} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', minHeight: mdDown ? '60vh' : '100vh' }}>
                    {!mdDown && <img src={Logo} alt='Offsiteio Logo' style={styles.logo} />}
                    <Steps mdDown={mdDown} step={6} />
                    <Box style={{ width: mdDown ? '90%' : '70%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '5%', height: mdDown ? 'auto' : '60vh', minHeight: mdDown ? 0 : '60vh', zIndex: 5 }}>
                        {!mdDown && <Typography variant="h3" component="h3" gutterBottom>
                            Can we help with any additional services to make your offsite more awesome?
                        </Typography>}
                        
                        <form
                            ref={form => setIframeRef(form)}
                            method="POST"
                            target="hidden_iframe"
                            action="https://script.google.com/macros/s/AKfycbwKl7jmL0Tx4m--qx0YSpyff7amkjbsaOnLVL9q2IYiOM3ma58qEh0C-vMWzeWUrp16CA/exec"
                            onSubmit={onSubmit}
                            style={{ width: '100%' }}
                        >
                            {/* Hidden inputs */}
                            <input 
                                type="hidden" 
                                name="date" 
                                value={(() => {
                                    const date = new Date();
                                    return ("0" + (date.getMonth() + 1).toString()).substr(-2) + "/" + 
                                           ("0" + date.getDate().toString()).substr(-2)  + "/" + 
                                           (date.getFullYear().toString()).substr(2);
                                })()}
                            />
                            <input type="hidden" name="name" value={name} />
                            <input type="hidden" name="email" value={email} />
                            <input type="hidden" name="cities" value={city} />
                            <input type="hidden" name="month" value={month} />
                            <input type="hidden" name="accommodation" value={hotelClass} />
                            <input type="hidden" name="nights" value={nights} />
                            <input type="hidden" name="colleagues" value={colleagues} />
                            <input type="hidden" name="services" value={additionalServices.concat(otherIdea ? [otherIdea] : []).join(', ')} />

                            <Box sx={{ m: 0, mb: 0, mt: mdDown ? 0 : 2, width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <FormGroup sx={{ maxHeight: mdDown ? 'none' : '35vh' }}>
                                    {options.map(opt => (
                                        <FormControlLabel
                                            key={opt}
                                            control={
                                                <Checkbox
                                                    checked={selected[opt]}
                                                    onChange={() => handleSelect(opt)}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontWeight: 600, mt: '2px' }} gutterBottom>
                                                    {opt}
                                                </Typography>
                                            }
                                        />
                                    ))}
                                </FormGroup>
                            </Box>

                            <Typography sx={{ fontWeight: 700, mt: '10px' }} gutterBottom>Other ideas?</Typography>
                            <TextField
                                size="medium"
                                sx={{ marginTop: '2%', width: '100%' }}
                                onChange={e => handleOtherIdea(e.target.value)}
                                value={otherIdea}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" />
                                    )
                                }}
                                placeholder={' Type here and we can discuss'}
                                type='text'
                            />

                            <Button
                                text='Submit'
                                styles={{
                                    marginTop: '7%',
                                    width: '100%',
                                    height: '3.5rem',
                                    minHeight: '3.5rem'
                                }}
                                loading={loading}
                                type='submit'
                            />
                        </form>
                    </Box>
                    <img src={Airplanes2} alt='Airplanes Illustration' style={{ width: '100%', position: 'relative', bottom: 0 }} />
                </Grid>
            </Grid>
        </>
    )
}

export default AdditionalServices