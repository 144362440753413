import { Box, Grid, Typography, useMediaQuery, Checkbox } from '@mui/material'
import Logo from '../assets/logo/offsiteio-logo.svg'
import AutumnIllustration from '../assets/img/autumn.svg'
import SpringIllustration from '../assets/img/spring.svg'
import WinterIllustration from '../assets/img/winter.svg'
import SummerIllustration from '../assets/img/summer.svg'
import DefaultIllustration from '../assets/img/default-month.svg'
import { useState, useContext, useEffect } from 'react'
import Airplanes2 from '../assets/img/airplanes2.svg'
import Button from '../components/Button'
import Pill from '../components/Pill'
import { useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import {Context} from '../Context'
import { showToast } from '../utils/showToast'
import Steps from '../components/Steps'

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

const Months = () => {
    const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const [name, setName, email, setEmail, city, setCity, month, setMonth, nights, setNights, hotelClass, setHotelClass, colleagues, setColleagues, additionalServices, setAdditionalServices] = useContext(Context)
    const navigate = useNavigate()
    const [selected, setSelected] = useState(null)
    const [season, setSeason] = useState('winter')
    const [dontKnow, setDontKnow] = useState(false)
    const styles = {
        logo: {
            margin: '3%',
            marginBottom: '1%',
            alignSelf: 'flex-end',
            width: '10%'
        },
        titleMobile: {
            position: 'absolute',
            top: '3%',
            width: '90%',
        }
    };

    const handleIllustrations = (month) => {
        switch (month) {
            case 'Jan':
                return WinterIllustration
            case 'Feb':
                return WinterIllustration
            case 'Mar':
                return SpringIllustration
            case 'Apr':
                return SpringIllustration
            case 'May':
                return SpringIllustration
            case 'Jun':
                return SummerIllustration
            case 'Jul':
                return SummerIllustration
            case 'Aug':
                return SummerIllustration
            case 'Sep':
                return AutumnIllustration
            case 'Oct':
                return AutumnIllustration
            case 'Nov':
                return AutumnIllustration
            case 'Dec':
                return WinterIllustration
            default:
                return DefaultIllustration
        }
    }

    const handleSelect = (month) => {
        setSelected(month)
        setMonth(month)
    }

    const handleSelectCheckbox = () => {
        let current = dontKnow
        setDontKnow(!current)
        if (!current) {
            setSelected(null)
            setMonth('None')
        }
    }

    const onSubmit = () => {
        if (month) {
            navigate('/select-nights')
        } else {
            showToast('error', "Please select a month you'll like to travel")
        }
    }

    
    useEffect(() => {
        if (!name || !email || !city) {
            navigate('/')
        }
    }, [])

    return (
        <Grid container style={{
            height: mdDown ? '100%' : '100vh',
            minHeight: '100vh',
            overflow: 'hidden',
        }}>
            <Grid item md={7} xs={12} style={{ backgroundColor: '#EFC2CB', height: mdDown ? '40vh' : '100vh', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                <CSSTransition
                    key={selected}
                    classNames='fade'
                    in={true}
                    appear={true}
                    timeout={200}
                >
                    <img src={handleIllustrations(selected)} alt='Season Illustration' style={{ width: '110%' }} />
                </CSSTransition>
                {mdDown && 
                <Typography variant="h2" component="h2" gutterBottom style={styles.titleMobile}>
                    When are you planning your next offsite?
                </Typography>
                }
            </Grid>
            <Grid item md={5} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', minHeight: mdDown ? '60vh' : '100vh' }}>
                {!mdDown && <img src={Logo} alt='Offsiteio Logo' style={styles.logo} />}
                <Steps mdDown={mdDown} step={2} />
                <Box style={{ width: mdDown ? '90%' : '70%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '5%', height: mdDown ? 'auto' : '60vh', minHeight: mdDown ? 0 : '60vh', zIndex: 5 }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {!mdDown && <Typography variant="h3" component="h3" gutterBottom>
                            When are you planning your next offsite?
                        </Typography>}
                        <Typography sx={{ fontSize: '1.2rem', fontWeight: '600', mt: mdDown ? '10%' : 'auto' }} gutterBottom>
                            What would be <strong>your ideal month?</strong>
                        </Typography>
                        <Typography gutterBottom>
                            (it doesn't have to be the final decision)
                        </Typography>
                    </Box>
                    <Box sx={{ m: 5, mb: 0, mt: 1, width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        {months.map(m => <div style={{ width: '30%', marginBottom: '2%' }}><Pill fontSize={'0.8rem'} text={m} onSelect={!dontKnow ? handleSelect : null} selected={m === selected} w100 /></div>)}
                    </Box>
                    <Box sx={{ m: 5, mb: 0, mt: 1, width: '100%', display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            color="primary"
                            checked={dontKnow}
                            indeterminate={false}
                            onChange={handleSelectCheckbox}
                        />
                        <Typography sx={{ paddingTop: '2px' }} gutterBottom>
                            I don't know
                        </Typography>
                    </Box>
                    <Button
                        text='Continue'
                        styles={{
                            marginTop: '7%',
                            width: '100%',
                            height: '3.5rem',
                            minHeight: '3.5rem'
                        }}
                        onSubmit={onSubmit}
                    />
                </Box>
                <img src={Airplanes2} alt='Airplanes Illustration' style={{ width: '100%', position: 'relative', bottom: 0 }} />
            </Grid>
        </Grid>
    )
}

export default Months